 .panel {
     margin-bottom: 0px;
 }

 .chat-window {
     bottom: 0;
     right: 0;
     position: fixed;
     float: left;
     margin-left: 10px;
 }

 .chat-window>div>.panel {
     border-radius: 5px 5px 0 0;
 }

 .icon_minim {
     padding: 2px 10px;
 }

 .msg_container_base {
     background: #e5e5e5;
     margin: 0;
     padding: 0 10px 10px;
     height: 300px;
     overflow-x: hidden;
 }

 .top-bar {
     background: #666;
     color: white;
     padding: 10px;
     position: relative;
     overflow: hidden;
 }

 .msg_receive {
     padding-left: 0;
     margin-left: 0;
 }

 .msg_sent {
     padding-bottom: 20px !important;
     margin-right: 0;
 }

 .messages {
     background: white;
     padding: 10px;
     border-radius: 2px;
     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
     max-width: 100%;
 }

 .messages>p {
     font-size: 13px;
     margin: 0 0 0.2rem 0;
 }

 .messages>time {
     font-size: 11px;
     color: #ccc;
 }

 .msg_container {
     padding: 10px;
     overflow: hidden;
     display: flex;
 }

 img {
     display: block;
     width: 100%;
 }

 .avatar {
     position: relative;
 }

 .base_receive>.avatar:after {
     content: "";
     position: absolute;
     top: 0;
     right: 0;
     width: 0;
     height: 0;
     border: 5px solid #FFF;
     border-left-color: rgba(0, 0, 0, 0);
     border-bottom-color: rgba(0, 0, 0, 0);
 }

 .base_sent {
     justify-content: flex-end;
     align-items: flex-end;
 }

 .base_sent>.avatar:after {
     content: "";
     position: absolute;
     bottom: 0;
     left: 0;
     width: 0;
     height: 0;
     border: 5px solid white;
     border-right-color: transparent;
     border-top-color: transparent;
     box-shadow: 1px 1px 2px rgba(black, 0.2);
 }

 .msg_sent>time {
     float: right;
 }



 .msg_container_base::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     background-color: #F5F5F5;
 }

 .msg_container_base::-webkit-scrollbar {
     width: 12px;
     background-color: #F5F5F5;
 }

 .msg_container_base::-webkit-scrollbar-thumb {
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
     background-color: #555;
 }

 .btn-group.dropup {
     position: fixed;
     left: 0px;
     bottom: 0;
 }

 .chat-input {
    background-color: #fff;
    border: 1px solid #e5e5e5;
 }

  .chat-input input {
      padding: 5px;
  }